import * as Sentry from "@sentry/astro";

Sentry.init({
  dsn: "https://69290baea1a7f0f550f4177c7cd9c123@o421496.ingest.sentry.io/4506496674299904",
  debug: false,
  environment: import.meta.env.PUBLIC_VERCEL_ENV,
  release: import.meta.env.PUBLIC_VERCEL_GIT_COMMIT_SHA,
  tracesSampleRate: 1,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1,
});